.header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #449B67;
  padding: 0 5%;
}
.header .containerLogo {
  width: 200px;
  height: 60px;
}
.header .containerLogo img {
  height: 100%;
  width: 100%;
}

#menuIcone svg {
  color: #9a9a9a;
  margin: 0 4px;
  height: 20px;
  width: 28px;
}
@media (max-width: 550px) {
  .header {
    padding: 0 0.5rem;
  }
  .header .containerLogo {
    width: 40%;
    height: 60%;
  }
  #menuIcone svg {
    display: none;
    margin: 0;
  }
}
