* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
body {
  min-height: 100vh;
  background-color: #efefef;
}

.containerInputs {
  width: 100%;
  color: #727272;
  margin: 1.2rem 0;
  position: relative;
}
.containerInputs label {
  font-size: 1.1rem;
  position: absolute;
  color: #8f8f8f;
  top: 10px;
  left: 0;
  pointer-events: none;
  transition: all 0.2s ease;
}
.containerInputs input {
  width: 100%;
  height: 36px;
  font-size: 1.2rem;
  background: inherit;
  border: none;
  border-bottom: 2px solid;
  color: inherit;
}
.containerInputs input:focus,
.containerInputs input:valid {
  outline: none;
  border-width: 2px;
  margin-bottom: -1px;
  border-color: #363636;
}
.containerInputs input:focus + label,
.containerInputs input:valid + label {
  transform: translateY(-22px);
  font-size: 85%;
  color: #7a7979;
}
.mensagemErro {
  color: #e00b0b;
  font-size: 0.9rem;
  margin: 0;
}
.containerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.containerButton button {
  width: 40%;
  padding: 9px;
  background-color: #525252;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  font-weight: bold;
}
.default {
  cursor: default;
}
.pointer {
  cursor: pointer;
}
@media (max-width: 600px) {
  .containerInputs input {
    font-size: 0.9rem !important;
    border-bottom: 1px solid !important;
  }
  .containerInputs label {
    font-size: 0.8rem !important;
  }
  .containerInputs {
    margin: 0.5rem 0;
    width: 90%;
  }
  .containerButton button {
    font-size: 0.7rem !important;
    margin-top: 0.3rem;
  }
}
