@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
} 

@font-face { font-family: "IBM Plex Bold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

@font-face { font-family: "IBM Plex Sans medium"; 
  src: url('../../assets/font/IBM/IBMPlexSans-MediumItalic.ttf');
}

.containerTelaConf {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 1rem 5rem 0 5rem;

  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from "visible" */
  text-overflow: clip;

  width: 90vw;
}

.ContConfirmar{
  width: 87vw;
}

.dTotalizador{
  display: none;
}

.BoxCard {
  background-color: #FFFFFF;
  font-family: "IBM Plex Sans medium";
  padding: 0 !important;
  width: 98%;
  min-height: 100px;
  margin-left: 1%;
  height: auto;
}

.Title {
  font-family: "IBM Plex Bold";
}

.Titlesub {
  font-family: "IBM Plex Bold";
  font-size: 14pt;
}

.Titlesub2 {
  font-family: "IBM Plex Bold";
  font-size: 12pt;
}

.TitlesubVal {
  font-family: "IBM Plex Bold";
  font-size: 12pt;
  text-align: right;
}

.TitleInfo {
  font-family: "IBM Plex Sans Italic";
  font-size: 12pt;
}

.TitleInfoVal {
  font-family: "IBM Plex Sans Italic";
  font-size: 12pt;
  text-align: right;
}

.editar {
  color: "#41895E";
  text-align: center;
}

@media (width: 1866px) {
  .containerTelaConf{
    margin: 1rem 7.5rem 0 7.5rem;
  }
}

@media (width: 1850px) {
  .containerTelaConf{
    padding: 1rem 2rem 0 2rem;
  }

  .ContConfirmar{
    width: 87vw
  }
}

@media (max-width: 1200px) {
  .containerTelaConf{
    margin: 1rem 1rem 0 1rem;
    padding: 0;
    width: 98vw;
  }

  .ContConfirmar{
    width: 96vw;
  }
}

@media (max-width: 980px) {
  .containerTelaConf{
    margin: 1rem 1rem 0 1rem;
    padding: 0;
    width: 98vw;
  }

  .ContConfirmar{
    width: 96vw;
  }
}

@media (max-width: 780px) {
  .containerTelaConf{
    margin: 1rem 1rem 0 1rem;
    padding: 0;
    width: 95vw;
  }

  .ContConfirmar{
    width: 95vw;
  }
}

@media (max-width: 400px) {
  .containerTelaConf{
    margin: 1rem 1rem 0 1rem;
  }

  .ContConfirmar{
    width: 90vw;
  }
}

/*@media (max-width: 600px) {
  .ContConfirmar{
    margin: 0
  }
  
  .containerTelaConf {
    margin: 2em;
  }
}

@media (max-width: 1155px) {
  .containerTelaConf{
    margin: 1rem 1rem 0 1rem;
  }

  .ContConfirmar{
    margin: 0px;
    width: 40;
    max-width: 1015px;
  }
}

@media (min-width: 1450px) {
  .containerTelaConf{
    width: auto;
    
    margin: 1rem 6rem 0 7rem;
  }

  .ContConfirmar{
    width: auto;
    
    margin: 0px;
  }
}

@media (max-width: 750px) {
  .ContConfirmar {
    margin: 0px;
    
  }

  .containerTelaConf {
    margin: 1em;
  }
}

@media (max-width: 650px) {
  .ContConfirmar {
    margin: 0px;
    width: 580px;
  }

  .containerTelaConf {
    margin: 2em;
  }
}

@media (max-width: 550px) {
  .containerTelaConf{
    margin: 1em;
  }

  .ContConfirmar {
    margin: 0px;
    width: 500px;
  }

  .Btn{
    justify-content: center !important;
  }

  .resumo{
    display: flex;
    justify-content: center;
  }

  .TitleInfoVal{
    text-align: left;
  }

  .dTotalizador{
    display: block;
  }
}

@media (max-width: 450px) {
  .containerTelaConf {
    margin: 1em;
  }

  .ContConfirmar{
    width: 400px;
  }
}

@media (max-width: 350px) {
  .containerTelaConf{
    margin: 1em;
  }

  .ContConfirmar {
    margin: 0px;
    width: 320px;
  }
}*/