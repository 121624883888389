.containerLogin {
  padding: 2rem;
  width: 550px;
  height: 520px;
  border-radius: 10px;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  box-shadow: -3px 6px 17px 3px rgba(0, 0, 0, 0.33);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.containerFormulario {
  width: 100%;
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerImgLogo {
  height: 90px;
  width: 300px;
  margin: 10px 0 25px 0;
}
.containerErro {
  margin-top: 8px;
}
.containerImgLogo img {
  width: 100%;
  height: 100%;
}
.containerLogin a {
  color: #575757;
  text-decoration-line: underline;
}
.containerLogin a:hover {
  color: #343434;
  text-decoration-line: underline;
}
.containerLogin .containerButton {
  flex-direction: column;
}
.containerLogin .containerFinal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerLogin .textoFinal {
  margin-top: 1rem;
  color: #525252;
  font-size: 1.1rem;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .containerLogin {
    width: 90%;
    height: auto;
    padding: 0;

    top: 35%
  }
  .containerFormulario {
    padding: 0.5rem 1rem 1rem 1rem;
  }
  .containerImgLogo {
    width: 50%;
    height: auto;
  }
  .containerLogin .textoFinal {
    font-size: 0.8rem;
    margin: 0.5rem;
  }
  .containerImgLogo {
    margin: 20px 0 0 0;
  }
}
