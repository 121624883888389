@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
} 

@font-face { font-family: "IBM Plex Bold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

@font-face { font-family: "IBM Plex Sans medium"; 
  src: url('../../assets/font/IBM/IBMPlexSans-MediumItalic.ttf');
} 

@font-face { font-family: "IBM Plex Sans SemiBold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-SemiBold.ttf');
} 

.titleModal {
  font-family: "IBM Plex Sans medium";
}

.title {
  font-family: "IBM Plex Sans Italic";
  font-size: 12pt;
}

.subtitle {
  font-family: "IBM Plex Sans SemiBold";
  font-size: 12pt;
}

.sTitle{
  display: flex;
  justify-content: space-between;
}