@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

@font-face { font-family: "IBMPlexSans SemiBold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-SemiBold.ttf');
} 

.containerCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #41895E;
  color: #fff;
  height: 150px;

  word-wrap: break-word;
}

.containerCard .infoCard h6 {
  color: #fff;
  font-family: "IBM Plex Sans Italic";
  word-wrap: break-word;
}

.containerCard .infoCard h5 {
  color: #fff;
  
  margin-bottom: 0;
  font-family: 'IBMPlexSans SemiBold';
  font-style: normal;
}

.containerCard .iconeCard {
  color: #fff;
  width: 60px;
  height: 50px;
}
.containerCard .iconeCard svg {
  height: 100%;
  width: 100%;
  color: #fff;
}
@media (max-width: 400px) {
  .containerCard {
    padding: 1rem 1rem;
    height: 290px;
  }
  .infoCard{
    padding: 2px;
  }
  .containerCard .infoCard h6 {
    font-size: 16pt;
  }
  .containerCard .infoCard h4 {
    font-size: 14pt;
  }
  .containerCard .iconeCard {
    width: 55px;
    height: 45px;
  }
}

@media (max-width: 800px) {
  .containerCard{
    height: 55vh;
  }
  .containerCard .infoCard h6 {
    font-size: 14pt;
  }
  .containerCard .infoCard h4 {
    font-size: 12pt;
  }
}
