@font-face { font-family: "IBM Plex Sans"; 
	src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
  } 
  
  @font-face { font-family: "IBM Plex Bold"; 
	src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
  } 
  
  @font-face { font-family: "IBM Plex Sans Italic"; 
	src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
  } 

*{
	font-family: "IBM Plex San";
}

.table-responsive {
	font-family: "IBM Plex San";
}

.cell {
	text-overflow: ellipsis; 
}