@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

.subheader {
  width: 100%;
  top: 75px;
  height: 40px;
  background-color: #41895E;
  z-index: 996;
}

.subheader .alignment {
  max-width: 1360px;
  height: 100%;

  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  column-gap: 1rem;
  padding: 0 6rem;
}

.subheader .alignment img {
  width: 20px;
  margin-right: 5px;
}

.subheader .alignment h1 {
  font-family: "IBM Plex Sans Italic";
  font-weight: 400;
  font-size: 1.20rem;
  color: #fff;

  margin: 0;
}

.eu{
  display: inherit;
  align-items: inherit;
}

@media(width: 1860px){
  .subheader .alignment {
    max-width: 1860px;
    padding: 0 6em;
  }
}

@media (max-width: 1850px) {
  .subheader .alignment {
    padding: 0 4.8em 0 5em;
    width: 95vw;
    max-width: 1800px;
  }
}

@media (max-width: 1600px) {
  .subheader .alignment {
    padding: 0 3em;
  }

  .subheader .alignment h1 {
    font-size: 1rem;
    text-align: right;
  }
}

@media (max-width: 1400px) {
  .subheader .alignment {
    padding: 0 4em;
  }

  .subheader .alignment h1 {
    font-size: 1rem;
    text-align: right;
  }
}

@media (max-width: 950px) {
  .subheader .alignment {
    padding: 0 3em;
  }

  .subheader .alignment h1 {
    font-size: 1rem;
    text-align: right;
  }
}

@media (max-width: 650px) {
  .subheader .alignment {
    padding: 0 3.8em;
  }

  .subheader .alignment h1 {
    font-size: 1rem;
    text-align: right;
  }
}

@media (max-width: 550px) {
  .subheader .alignment {
    padding: 0 2em;
  }

  .subheader .alignment h1 {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .subheader .alignment {
    padding: 0 2em;
  }

  .subheader .alignment h1 {
    font-size: 0.6rem;
  }
}

@media (width: 1866px) {
  .subheader .alignment {
    padding: 0 8em;
    max-width: 1866px;
  }
}